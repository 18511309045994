import React from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"
// import "react-id-swiper/lib/styles/css/swiper.css"
import "./careerSwiper.scss"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import { StaticImage } from "gatsby-plugin-image"

import c1 from "../images/c1.jpg"
import c2 from "../images/c2.jpg"
import c3 from "../images/c3.jpg"
import c4 from "../images/c4.jpg"
import c5 from "../images/c5.jpg"
import c6 from "../images/c6.jpg"
import c7 from "../images/c7.jpg"
import c8 from "../images/c8.jpg"
import c9 from "../images/c9.jpg"
import c10 from "../images/c10.jpg"
import c11 from "../images/c11.jpg"

class CareerSwiper extends React.Component {
  render() {
    const params = {
      modules: [Pagination, Navigation],
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 30,
      breakpoints: {
        280: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
      containerClass: "swiper-container pb-5",
      nested: true,

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    }

    return (
      <div className="career-gallery container-fluid px-0">
        <Swiper {...params}>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c1.jpg"
              alt="worklife1"
              className="img-size mobile-hide"
            />
            <img
              src={c1}
              placeholder="blurred"
              layout="constrained"
              alt="worklife1"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c2.jpg"
              alt="worklife2"
              className="img-size mobile-hide"
            />
            <img
              src={c2}
              placeholder="blurred"
              layout="constrained"
              alt="worklife2"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c3.jpg"
              alt="worklife3"
              className="img-size mobile-hide"
            />
            <img
              src={c3}
              placeholder="blurred"
              layout="constrained"
              alt="worklife3"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c4.jpg"
              alt="worklife4"
              className="img-size mobile-hide"
            />
            <img
              src={c4}
              placeholder="blurred"
              layout="constrained"
              alt="worklife4"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c5.jpg"
              alt="worklife5"
              className="img-size mobile-hide"
            />
            <img
              src={c5}
              placeholder="blurred"
              layout="constrained"
              alt="worklife5"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c6.jpg"
              alt="worklife11"
              className="img-size mobile-hide"
            />
            <img
              src={c6}
              placeholder="blurred"
              layout="constrained"
              alt="worklife6"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c7.jpg"
              alt="worklife7"
              className="img-size mobile-hide"
            />
            <img
              src={c7}
              placeholder="blurred"
              layout="constrained"
              alt="worklife7"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c8.jpg"
              alt="worklife8"
              className="img-size mobile-hide"
            />
            <img
              src={c8}
              placeholder="blurred"
              layout="constrained"
              alt="worklife8"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c9.jpg"
              alt="worklife9"
              className="img-size mobile-hide"
            />
            <img
              src={c9}
              placeholder="blurred"
              layout="constrained"
              alt="worklife9"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c10.jpg"
              alt="worklife10"
              className="img-size mobile-hide"
            />
            <img
              src={c10}
              placeholder="blurred"
              layout="constrained"
              alt="worklife10"
              className="img-fluid mobile-display"
            />
          </div>
          <div className="spotlight-item">
            <StaticImage
              placeholder="blurred"
              layout="constrained"
              src="../images/c11.jpg"
              alt="worklife6"
              className="img-size mobile-hide"
            />
            <img
              src={c11}
              placeholder="blurred"
              layout="constrained"
              alt="worklife11"
              className="img-fluid mobile-display"
            />
          </div>
        </Swiper>
      </div>
    )
  }
}

export default CareerSwiper
